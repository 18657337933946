import { instance } from "../../config/axios.config"

const referenceLocationBuy = (data: Array<object>) => {
    console.log('data: ', data)
    return instance.post('ReferenceLocationBuy/Save', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    })
}

export { referenceLocationBuy }