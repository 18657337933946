import { instance } from "../../config/axios.config";

const getAllPublicEvents = () => {
    return instance.get('PublicEvent/GetAll', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    });
}

export { getAllPublicEvents }