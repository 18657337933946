/* eslint-disable react-hooks/rules-of-hooks */
import { AppBar, Box, Button, Container, Grid, Toolbar, Typography } from '@mui/material';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from 'react-router-dom';
import { auth, signOut } from '../providers/provider.auth';

export const NavBar: React.FC<{}> = () => {
  const [ user, loading ] = useAuthState(auth)
  const navigate = useNavigate()

  const redirectToHomeAfterSignOut = () => {
      signOut()
      navigate('/')
  }
    return(
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="sticky">
              <Toolbar>
                  <Container maxWidth="xl">
                    <Grid 
                      container 
                      direction="row" 
                      justifyContent="space-between" 
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography 
                            component="a" href="/"
                            style={{
                              textDecoration: "none", 
                              color: "#ffffff"
                        }}>
                          Ticket Evento
                        </Typography>
                      </Grid>
                      <Grid item>
                      {
                        (user) ?
                            <Grid display='flex' direction='row'>
                                <Link to={'/userprofile'}>
                                    <Button style={{ padding: 20, color:'white' }}>User Profile</Button>
                                </Link>
                                <Button onClick={redirectToHomeAfterSignOut}
                                        style={{color: 'white'}}>
                                    sign Out
                                </Button>
                            </Grid>
                        :
                        <Link to={'/login'}>
                            <Button style={{ padding: 20, color:'white' }}>Login</Button>
                        </Link>
                      }
                      </Grid>
                    </Grid>
                  </Container>
              </Toolbar>
          </AppBar>
        </Box>
    );
}