import { instance } from "../../config/axios.config"

const getPurposeAllEvents = () => {
  return instance.get('PublicEvent/GetAllPurposesEvents', {
    headers:{
        'Content-Type': 'application/json',
        'Authorization': process.env.REACT_APP_API_TICKETEVENTO
    }
  })
}

export { getPurposeAllEvents }