/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, Pagination, Stack, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Swal from 'sweetalert2';
import { TypeEvent } from '../../interfaces/event-interface/event.interface';
import { Category, Country } from '../../interfaces/home-interface/home.interface'
import { getAllPurposesEvents } from '../../services/events/event.allpurposesevents';
import { getPurposeAllEvents } from '../../services/events/event.purposesallevents';
import { getAllcategories } from '../../services/masters/category.list';
import { getAllPublicEvents } from '../../services/events/event.list';
import { getAllCities } from '../../services/masters/city.list';
import { CarouselComponent } from '../../components/CarouselComponent';
import { CardComponent } from '../../components';

let eventImages: string[];
const HomePage = () => {
  const [ allEvents, setAllEvents ] = useState<TypeEvent[]>([]);
  const [ allCategories, setAllCategories ] = useState<Category[]>([])
  const [ allCities, setAllCities ] = useState<Country[]>([])
  const [ category, setCategory ] = useState('')
  const [ city, SetCity ] = useState('')
  const [ eventData, setEventData ] = useState(allEvents) 

    useEffect(() => {
      const getAllEventImages = () => {
        try {
          const urlSegments = allEvents.map(e => (e.eventWithPriority === true) ? e.imgSrc.replaceAll('\\','/') : '')
          eventImages = urlSegments.map(url => `${process.env.REACT_APP_API_BASE_URL}${url}`)
          console.log('eventImageUrl: ', eventImages);
        } catch (error) {
          console.log('Error: ', error);
        }
      }
      getAllEventImages();
    },[allEvents])

    const containerStyle =  {
      width: '1500px',
      height: '500px',
      margin: '0 auto'
    };
  
    const getAllPurposesEvent = async () => {
      try{
        const {data} = await getPurposeAllEvents()
        console.log('Eventos Finalizados: ', data);
      }
      catch(error){
        console.log(error);
      }
    }
    
      const listPurposesEvents = async () => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Cargando Eventos...',
          showCancelButton: false,
          timer: 1500})
          getAllPurposesEvent()
        try {
          const {data} = await getAllPurposesEvents()
          setAllEvents(data)
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops ...Ocurrio un Error',
            text: `Error en la carga de los eventos finalizados, ${error}`
          })
        }
      }
    
      const listEvent = async () => {
        Swal.fire({ 
          position: 'center',
          icon: 'success',
          text: 'Cargando Eventos...',
          showConfirmButton: false,
          timer: 1000})
        try {
          const { data } = await getAllPublicEvents();
          setAllEvents(data);
          setEventData(data)
          console.log(data);
        } catch (error) {
          Swal.fire({
            icon: 'error',  
            title: 'Oops...Ocurrio un Error',
            text: `Error en la carga de los eventos, ${error}`
          })
        }
      }
    
      useEffect(() => {
        listEvent()
      },[])
    
      useEffect(() => {
        const getCategories = async () => {
          try {
            const { data } = await getAllcategories()
            setAllCategories(data)
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...Ocurrió un Error',
              text: `Error al obtener las Categorias, ${error}`
            })
          }
        }
        getCategories()
      }, [])
    
      useEffect(() => {
        const getCities = async () => {
          try {
            const { data } = await getAllCities()
            setAllCities(data)
            //console.log(data)
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...Ocurrió un Error',
              text: `Error al obtener las ciudades, ${error}`
            })
          }
        }
        getCities()
      }, [])
    
      //@ts-ignore
      const filterByCategory = ({target}) => {
        const {value} = target
        try {
          const eventFilterByCategory = (allEvents.length !== 0 && allEvents !== undefined) &&
                         allEvents.filter((event) => (event.categoriesName.includes(value)))
                         //@ts-ignore
                  setEventData(eventFilterByCategory);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...Ocurrió un error',
            text: `Error al filtrar eventos por categoria, ${error}`
          })
        }
      }
      //@ts-ignore
      const filterByCity = ({target}) => {
        const {value} = target
        try {
          const eventFilterByCity = (allEvents.length !== 0) && allEvents.filter((event) => 
              (event.cityName === value))
              //@ts-ignore
          setEventData(eventFilterByCity)
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...Ocurrió un Error',
            text: `Error al filtrar eventos por ciudad, ${error}`
          })
        }
      }

      const filterbyDate = () => {
        const date = new Date("2023-11-04T00:00:00")
        let filtrado: TypeEvent[];
        //@ts-ignore
        const eventsFilter: TypeEvent[] = allEvents.filter(e => e.startDate <= date && e.endDate >= date)
                                setEventData(eventsFilter)
                                console.log(eventsFilter);
      }

    return(
        <>
          <Grid 
            container 
            direction="row" 
            alignItems="center" 
            justifyContent="center"
            sx={{ minHeight: "100vh"}}
          >
            <Grid item display='flex'>
              {
              <CarouselComponent autoPlay={true} />
              }
            </Grid>
            <Container sx={{mt: 5}}>
            <Grid container   
                  display='flex' direction='row' justifyContent='space-around' 
                  spacing={2} className="container_filter_section">
              <Grid item justifyContent='center' alignItems='center'>
                <Button onClick={() => listPurposesEvents()}
                        style={{height: 40}}>
                          Eventos Finalizados
                </Button>
              </Grid>
              <Grid item justifyContent='center' alignItems='center'>
                <Button onClick={() => listEvent()}
                        style={{height: 40}}>
                          Todos los Eventos
                </Button>
              </Grid>
              <div>
                <FormControl variant="standard" sx={{ minWidth: 120, maxWidth: 140  }}>
                  <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={category}
                      label="category"
                      onChange={filterByCategory}
                    >
                      {allCategories.map((categoria) => (
                        <MenuItem value={categoria.name}>{categoria.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <Grid >
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                      value={city}
                      label="City"
                      onChange={filterByCity}
                    >
                      {allCities.map((ciudad) => (
                        //@ts-ignore
                        ciudad.items.map((item) => 
                        //@ts-ignore
                          (item.items.map((c) => 
                            (<MenuItem value={c.name}>{c.name}</MenuItem>))))
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item justifyContent='center' alignItems='center'>
                <Button onClick={filterbyDate}>Filtro por fecha</Button>
                {/* {<Typography>Filtro por fecha</Typography>} */}
              </Grid>
            </Grid>
              <div>
                    {
                      ((eventData?.length !== 0) ? (
                      <Grid container spacing={2} direction='row'>
                        {allEvents.map((event) => (
                          <Grid container item xs={12} md={6} lg={4}>
                            <CardComponent key={event.id}
                                          eventId={event.id}
                                          //@ts-ignore
                                          startDate={(event.functions !== undefined) 
                                              && event.functions.map((funt) => 
                                                (funt.startDate)).toLocaleString()}
                                                event={event}/>
                          </Grid>
                        ))}
                      </Grid>
                      ) : ("No hay eventos disponibles según la búsqueda de interés"))
                    }
              </div>
              <Grid item display='flex' justifyContent='center' alignItems='center'
                sx={{mt: 2}}>
                <Stack spacing={2}>
                  <Pagination count={2} variant="outlined" shape="rounded" />
                </Stack>
              </Grid>
            </Container>
          </Grid>
          <Grid item sx={{mt: 5}}>
            <Divider/>
          </Grid>
      </>
    );
} 

export default HomePage;