import { instance } from "../../config/axios.config";

const getTicketType = () => {
    return instance.get('TicketType/GetAll', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    });
}

export { getTicketType }