import { instance } from "../../config/axios.config"

const getAllPurposesEvents = () => {
    return instance.get('PublicEvent/GetAllPurposesEvents', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    })
}

export { getAllPurposesEvents }