import { initializeApp } from '@firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import Swal from 'sweetalert2';


const firebaseConfig = {
  apiKey: 'AIzaSyBQhnvRpvzs36VlOZSC1knzvToDwukYooQ',
  authDomain: 'matipos-ticketeventoapp.firebaseapp.com',
  projectId: 'matipos-ticketeventoapp',
  storageBucket: 'matipos-ticketeventoapp.appspot.com',
  messagingSenderId: '789435920303',
  appId: '1:789435920303:web:678aec1da41e7a94eb895a',
  measurementId: 'G-M9J7JQVWXB',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

export const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrió un error',
      text: `Error: ${error}`
    })
  }
}

export const signInWithFacebook = async () => {
  try {
    await signInWithPopup(auth, facebookProvider)
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Ocurrio un error',
      text: `Error: ${error}`
    })
  }
}

export const signInWithTwitter = async () => {
  try {
    await signInWithPopup(auth, twitterProvider)
  } catch (error){
    Swal.fire({
      icon: 'error',
      title: 'Ocurrio un error',
      text: `Error: ${error}`
    })
  }
}

export const signOut = () => {
  auth.signOut()
}