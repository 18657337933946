import { useState, useEffect } from 'react'
import concierto_1  from '../assets/img/concierto_1.png'
import concierto_2  from '../assets/img/concierto_2.png';
import concierto_3  from '../assets/img/concierto_3.png';
import parche from '../assets/img/parche.png';
import freedoom_2023 from '../assets/img/freedoom_2023.jpg'
import styled from "styled-components";

const CarouselImage = styled.img`
  max-width: 2000px;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: 1s;
  &.loaded {
    opacity: 1;
  }
`;

// const CarouselButtonContainer = styled.div`
//   display: flex;
//   align-content: center;
//   flex-direction: row;
//   margin-top: 15px;
// `;

// const CarouselButton = styled.button`
//   color: white;
//   background-color: #eb118a;
//   padding: 8px;
//   margin: 0 5px;
// `;

interface Props{
  autoPlay?: boolean,
  showButtons?: boolean
}

export const  CarouselComponent = (props: Props) => {
  const images = [concierto_1, concierto_2, concierto_3, parche, freedoom_2023];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [loaded, setLoaded] = useState(false);

  const selectNewImage = (index: number, images: string[], next = true) => {
    setLoaded(false)
    setTimeout(() => {
      const condition = next ? selectedIndex < images.length - 1 : selectedIndex > 0;
      const nextIndex = next 
      ? (condition ? selectedIndex + 1 : 0) 
      : (condition ? selectedIndex - 1 : images.length - 1)
      setSelectedImage(images[nextIndex]);
      setSelectedIndex(nextIndex);
    }, 1000)
  }

  useEffect(() => {
    if(props.autoPlay){
      const interval = setInterval(() => {
        selectNewImage(selectedIndex, images);
      }, 8000)
      return () => clearInterval(interval);
    }
  })

    return (
    <>
      <CarouselImage
        src={`${selectedImage}`}
        alt="Event Images"
        className={loaded ? "loaded" : ""}
        onLoad={() => setLoaded(true)}
      />
    </>
  )
}