import { BrowserRouter } from 'react-router-dom';
import AppRouter from './Router';
import { Suspense } from 'react'
import { CircularProgress } from '@mui/material';
import './index.css'

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<CircularProgress className='circular_progress'/> }>
        <AppRouter />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
