import { LoginPage } from './pages';
import { Route, Routes } from 'react-router-dom';
import { RouterLayout } from './common/RouterLayout';
import routes from './Routes/allRoutes'
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import './index.css'

const AppRoute = () => {
    return(
      <Suspense fallback={<CircularProgress className='circular_progress'/> }>
        <Routes>
            <Route path='/' element={<RouterLayout />} >
              {routes.map((route, idx) => (
                <Route 
                  path={route.path} 
                  Component={route.component} 
                  key={idx}/>
              ))}
            </Route>
              <Route path='/login' element={<LoginPage />} />
        </Routes>
      </Suspense>
    );
}

export default AppRoute;