import { 
    Grid, 
    Select, 
    Divider, 
    TextField, 
    MenuItem, 
    Typography, 
    InputLabel, 
    FormControl, 
    Button 
    } from '@mui/material';
    import { useState, useEffect, useRef } from 'react';
    import emailjs from '@emailjs/browser'
    import { Link } from 'react-router-dom';
    import { v4 as uuidv4 } from 'uuid'
    import moment from 'moment';
    
    import { client } from '../../interfaces/eventdetail-interface/registerclient.interface';
    import { registerBuy } from '../../interfaces/eventdetail-interface/registerbuy.interface';
    import { getTicketType } from '../../services/events/event.tickettype';
    import { getTicketReceptionType } from '../../services/events/event.ticketreceptiontype';
    import { registerClient } from '../../services/client/registerclient';
    import { registerTicketBuy } from '../../services/client/registerticketbuy';
    import { PaymentMethod } from '../../components/EventDetail/index'
    import { referenceLocationB } from '../../interfaces/eventdetail-interface/referenceLocationbuy.interface';
    import { referenceLocationBuy } from '../../services/client/referencelocationbuy';
    import '../../index.css'
import { toHaveValue } from '@testing-library/jest-dom/matchers';
    
    interface TicketReceptionType {
        id:             number;
        description:    string;
        ticketTypeId:   number;
    }
    
    interface TicketType {
        id:             number;
        description:    string;
    }
    
    type propTarget = {
        target: {
            name:   string;
            value:  number | string;
        };
    }
    
    type valuesResumen = {
        values: [{
            stageDetailsId:number;
            value:         number;
            ticketCount:   number;
            locationName:  string;
            price:         number;
        }],
        nameEvent:         string;
        taxId:             number[];
    }
    
    let propsResumBuy: valuesResumen;
    
    export const FormBuy = (props: valuesResumen) => {
        const form: any = useRef()
    
        const [ ticketTypes, setTicketTypes ] = useState<TicketType[]>([])
        const [ showPaymentMethod, setShowPaymentMethod ] = useState<boolean>(false)
        const [ ticketReceptionTypes, setTicketReceptionTypes ] = useState<TicketReceptionType[]>([])
        const [ TicketReceptionType, setTickerReceptionType ] = useState<TicketReceptionType[]>([])
        //@ts-ignore
        const [ valuesFormBuy, setValuesFormBuy ] = useState<registerBuy>({})
        const { fullName = '', documentType, documentNumber = '', address = '',
                phone = '', email = '', ticketTypeId, ticketReceptionTypeId, eventName,
                stageDetailsId, ticketCount, taxesId, buyDate} = valuesFormBuy
        //@ts-ignore
        const [ valuesRegisterClient, setValuesRegiterClient ] = useState<client>({})
        const [ valuesReferenceLocationBuy, setValuesReferenceLocationBuy ] = useState<referenceLocationB[]>([])
        const [confirmationMessage, setConfirmationMessage] = useState<string>();
        const orderId = uuidv4()
        
        useEffect(() => {
            const getAllTicketTypes = async () => {
                const {data} = await getTicketType()
                setTicketTypes(data)
            }
            getAllTicketTypes()
        },[])
    
        useEffect(() => {
            const getAllTicketReceptionType = async() => {
                const {data} = await getTicketReceptionType()
                setTicketReceptionTypes(data)
            }
            getAllTicketReceptionType()
        }, [])
    
        const handleShowPaymentMethod = () => {
            //setShowPaymentMethod(true)
            const validateValues = Object.values(valuesFormBuy);
            for (let index = 0; index < validateValues.length; index++) {
                if(validateValues[index] == null){
                    return false;
                } 
            }
                triggerRegisterTicketBuy()
                triggerReferenceLocationBuy()
            }
            
            const filterTicketReceptionType = ({target}: propTarget) => {
                const {value} = target
                const data = (ticketReceptionTypes?.length !== 0) && 
                           ticketReceptionTypes.filter(receptionType => receptionType.ticketTypeId === value)
                           //@ts-ignore
            setTickerReceptionType(data)
            handleOnChange({target})
        }
    
        const handleOnChange = ({target}: propTarget) => {
            const { name, value } = target
            const buyOfDate = moment(new Date()).format('YYYY-MM-DD')
            setValuesFormBuy({
                ...valuesFormBuy, 
                [name]: value,
                orderId: orderId,
                eventName: props.nameEvent,
                stageDetailsId: props.values.map(element => (element.stageDetailsId)),
                ticketCount: props.values.map(count => count.ticketCount),
                taxesId: props.taxId.map(t => t),
                buyDate: buyOfDate
            })
            setValuesReferenceLocationBuy(
                //@ts-ignore
                props.values.map(element => (
                    {
                        orderId: orderId,
                        locationName: element.locationName,
                        ticketQuantity: element.ticketCount,
                        stageDetailsId: element.stageDetailsId,
                        ticketTypeId: value,
                        ticketReceptionTypeId: value
                    }))
                    )
            setValuesRegiterClient({
                ...valuesRegisterClient,
                [name]: value,
                registrationDate: buyOfDate
            })
            console.log('VALUES-TICKETBUY: ', valuesFormBuy)
            console.log('VALUES-REGISTERCLIENT: ', valuesRegisterClient)
            console.log('VALUES-REFERENCELOCATIONBUY: ', valuesReferenceLocationBuy)
        }
        
        const triggerRegisterClient = async() => {
            try {
                const {data} = await registerClient(valuesRegisterClient)
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        }
    
        const triggerRegisterTicketBuy = async () => {
            try{
                const {data} = await registerTicketBuy(valuesFormBuy)
                setConfirmationMessage(data);
                if(data === 'Superó el límite de compras permitidas para este evento'){
                    return
                } else {
                    triggerRegisterClient()
                    triggerReferenceLocationBuy()
                }
            } catch (error) {
                console.log(error)
            }
        }
    
        const triggerReferenceLocationBuy = async () => {
            try {
                const {data} = await referenceLocationBuy(valuesReferenceLocationBuy)
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        }
    
        const sendEmail = (e: any) => {
            e.preventDefault()
            emailjs.sendForm(
                'service_out4gse', 'template_txoj8a9', form.current, 'ttZy9mhgqYfkv1m-R')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error)
            })
        }
    
        return (
            <div>
                {
                    (!showPaymentMethod) ?
                <Grid container xs={12} sm={8} 
                      style={{maxWidth: 600, height: 600, marginLeft: 10, marginRight: 10}}>
                    <form ref={form} onSubmit={(e) => sendEmail(e)} style={{maxWidth: 650}}>
                        <Grid display='flex' direction='column' justifyContent='center' 
                                style={{margin: '1%', paddingLeft: '1%', paddingRight: '1%'}}>
                            <Grid display='flex' direction='row' justifyContent='center'
                                style={{ paddingBottom: '1%' }}>
                                <Typography>Formulario del proceso de compra</Typography>
                            </Grid>
                            <Divider/>
                            <Grid display='flex' direction='row' justifyContent='center'
                                style={{padding: '1%'}}>
                                <Typography variant='h5'>Información de facturación</Typography>
                            </Grid>
                            <Divider>
                                <Link to={'/login'} className="link_login_formbuy">
                                    Iniciar Sesión
                                </Link>
                            </Divider>
                            <Grid container display='flex' direction='row' justifyContent='space-around' 
                                  sx={{m: 1}}>
                                <Grid item>
                                    <TextField
                                        id="outlined-document-input"
                                        label="Full Name"
                                        required
                                        name='fullName'
                                        value={fullName}
                                        variant='standard'
                                        style={{minWidth: 230, maxWidth: 250, marginRight: '2%'}}
                                        onChange={(e) => handleOnChange(e)}/>
                                </Grid>
                                <Grid item>
                                    <FormControl >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Document Type
                                        </InputLabel>
                                        <Select style={{minWidth: 230, maxWidth: 250, marginLeft: '1%'}}
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            required
                                            label="Document Type"
                                            name='documentType'
                                            variant='standard'
                                            onChange={handleOnChange}
                                            >
                                                <MenuItem value={"Documento de Identidad"}>
                                                    Documento de Identidad
                                                </MenuItem>
                                                <MenuItem value={"Cedula de Ciudadania"}>
                                                    Cédula de Ciudadanía
                                                </MenuItem>
                                                <MenuItem value={"Cedula de Extranjeria"}>
                                                    Cédula de Extranjería
                                                </MenuItem>
                                                <MenuItem value={"Pasaporte"}>
                                                    Pasaporte
                                                </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container display='flex' direction='row' justifyContent='space-around'
                                sx={{m: 1}}>
                                <Grid item>
                                    <TextField
                                        id="outlined-document-input"
                                        label="Document"
                                        required
                                        type="text"
                                        name='documentNumber'
                                        value={documentNumber}
                                        variant='standard'
                                        style={{minWidth: 230, maxWidth: 250, marginRight: '2%'}}
                                        onChange={(e) => handleOnChange(e)}/>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="outlined-address-input"
                                        label="Address"
                                        required
                                        type="text"
                                        name='address'
                                        value={address}
                                        variant='standard'
                                        style={{minWidth: 230, maxWidth: 250, marginLeft: '1%'}}
                                        onChange={(e) => handleOnChange(e)}/>
                                </Grid>
                            </Grid>
                            <Grid container display='flex' direction='row' justifyContent='space-around'
                                sx={{m: 1}}>
                                <Grid item>
                                    <TextField
                                        id="outlined-phone-input"
                                        label="Phone"
                                        required
                                        type="number"
                                        name='phone'
                                        value={phone}
                                        variant='standard'
                                        style={{minWidth: 230, maxWidth: 250, marginRight: '2%'}}
                                        onChange={(e) => handleOnChange(e)}/>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Email"
                                        required
                                        type="email"
                                        name='email'
                                        value={email}
                                        variant='standard'
                                        style={{minWidth: 230, maxWidth: 250, marginLeft: '1%'}}
                                        onChange={(e) => handleOnChange(e)}/>
                                </Grid>
                            </Grid>
                            <Grid container display='flex' direction='row' justifyContent='space-around'
                                sx={{m: 1}}>
                                <Grid item>
                                    <FormControl >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Ticket Type
                                        </InputLabel>
                                        <Select style={{minWidth: 230, maxWidth: 250, marginRight: '2%'}}
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            required
                                            label="Ticket Type"
                                            name='ticketTypeId'
                                            variant='standard'
                                            onChange={filterTicketReceptionType}
                                            >{
                                                (ticketTypes) && ticketTypes.map((ticketType) => (
                                                    <MenuItem
                                                        key={ticketType.id}
                                                        value={ticketType.id}>
                                                        {ticketType.description}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl >
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Ticket Reception Type
                                        </InputLabel>
                                            <Select style={{minWidth: 230, maxWidth: 250, marginLeft: '1%'}}
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                required
                                                label="Ticket Reception Type"
                                                name='ticketReceptionTypeId'
                                                variant='standard'
                                                onChange={handleOnChange}>
                                                {
                                                    (TicketReceptionType) 
                                                            && TicketReceptionType.map((receptionType) => (
                                                                <MenuItem 
                                                                    key={receptionType.id}
                                                                    value={receptionType.id}>
                                                                        {receptionType.description}
                                                                </MenuItem>
                                                            ))
                                                }
                                            </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            (!confirmationMessage) ? 
                                <Grid container item xs={12} 
                                    style={{ minWidth: 400, maxWidth: 500, padding: '5%'}}>
                                <Typography>
                                        Señor Usuario, el email utilizado en 
                                        este formulario es el mismo que deberá usar para 
                                        iniciar sesión y para poder consultar el 
                                        histórico de sus compras en nuestra plataforma.
                                    </Typography>
                                </Grid>
                                : 
                                <Grid container item xs={12} justifyContent='center'
                                        style={{minWidth: 550, maxWidth: 600, padding: '5%'}}>
                                    <Typography color='brown'>
                                        {confirmationMessage}
                                    </Typography>
                                </Grid>
                        } 
                        <Grid display='flex' direction='row' justifyContent='center'>
                            <Button type="submit" onClick={() => handleShowPaymentMethod()}
                                style={{ background: '#002140', color: 'white', width: 90, margin: 10}}>
                                Pagar
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                : <PaymentMethod/>
                }
            </div>
        )
    }