import { Box, Container, Typography, Grid, Stack } from "@mui/material"
import { Link } from "react-router-dom"

export const Footer: React.FC<{}> = () => {
    return (
        <Box sx={{ flexGrow: 1}} position="sticky" style={{background: "#272727"}}>
            <Container maxWidth='md' sx={{ py: 6 }}>
                <Grid container spacing={6} display='flex' justifyContent='center'>
                    <Grid item xs={6} md={3} display='flex' justifyContent='center'>
                        <Stack spacing={2}>
                            <Link to={"/aboutticketevento"} 
                                  style={{
                                    textDecoration: 'none', 
                                    textTransform: 'none', 
                                    color: '#ffffff', 
                                    fontSize: 15
                                }}>
                                Acerca de Ticket Evento
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' justifyContent='center'>
                        <Stack spacing={2}>
                            <Link to={"/helppage"} 
                                  style={{
                                    textDecoration: 'none', 
                                    textTransform: 'none', 
                                    color: '#ffffff', 
                                    fontSize: 15
                                }}>
                                Ayuda
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' justifyContent='center'>
                        <Stack spacing={2}>
                            <Link to={"/usagepoliciepage"} 
                                  style={{
                                    textDecoration: 'none', 
                                    textTransform: 'none', 
                                    color: '#ffffff', 
                                    fontSize: 15
                                }}>
                                Politicas Legales
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={0} display='flex' justifyContent='center' pt={4}>
                    <Typography display='flex' justifyContent='center'>
                        Copyright &copy; 2023 Ticket Evento
                    </Typography>
                </Grid>
            </Container>
        </Box>
    )
}