/* eslint-disable eqeqeq */
import { 
    Card,  
    CardContent, 
    CardMedia, 
    Divider, 
    Grid, 
    Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { TypeEvent } from '../../interfaces/event-interface/event.interface';

type CardProps = {
    eventId: number,
    event: TypeEvent;
    startDate: Date
}

export const CardComponent: React.FC<CardProps> = ({eventId, event, startDate}) => {
    return (
        <Link to={`/eventdetail/${eventId}`}
                style={{textDecoration: "none"}}>
            <Card sx={{minWidth: 370, minHeight: 'auto'}}>
                <CardMedia component='img'
                        height='194'
                        image={(event.imgSrc == '') 
                            ? 'https://medellinstyle.com/wp-content/uploads/blc.jpg'
                            : `${process.env.REACT_APP_API_BASE_URL}${event.imgSrc}`}
                        alt='imagen evento'
                    />
                    <CardContent>
                        <Typography variant='h5' sx={{ mb:1.5 }}>{event.name}</Typography>
                        <Divider/>
                        {
                            ((event.functions !== undefined) && event.functions.length > 1 )
                                ?  
                                  <ul className='listDates'>{`Fecha: .`}
                                    {event.functions.map(f =>
                                        <li>
                                            {` ${moment(f.startDate).format("DD.MMM")} - `}
                                        </li>
                                    )}
                                  </ul>
                                : <Typography sx={{ mt:1.5 }}>Fecha: {moment(startDate).format("ddd, MMM. DD, YYYY")}</Typography>
                        }
                        <Typography sx={{ mt:1 }}>{`${event.departmentName}, ${event.cityName}`}</Typography>
                        <Typography sx={{ mt:1.5 }}><PlaceIcon/> {event.address}</Typography>
                    </CardContent>
            </Card>
        </Link>
    )
}   