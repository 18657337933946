// import LoginPage from "../pages/login/index";
// import HelpPage from "../pages/helppage/index";
// import EventDetail from "../pages/eventdetail";
// import UserProfilePage from "../pages/userprofile";
// import StartPage from "../pages/quickstartpage";
// import YourAccountPage from "../pages/youraccountpage"
// import UsagePoliciePage from "../pages/usagepolicepage";
// import AboutTicketEvento from "../pages/aboutticketevento";
// import EventAttendancePage from "../pages/eventattendancepage";
// import PoliciesAndTermsPage from "../pages/policesandtermspage";
import { lazy } from "react";
import HomePage from "../pages/home";
//const AsyncLoginPage = lazy(() => import("../pages/login"))
const AsyncHelpPage = lazy(() => import("../pages/helppage"))
const AsyncEventDetail = lazy(() => import("../pages/eventdetail"))
const AsyncUserProfilePage = lazy(() => import("../pages/userprofile"))
const AsyncStartPage = lazy(() => import("../pages/quickstartpage"))
const AsyncYourAccountPage = lazy(() => import("../pages/youraccountpage"))
const AsyncUsagePoliciePage = lazy(() => import("../pages/usagepolicepage"))
const AsyncAboutTicketEvento = lazy(() => import("../pages/aboutticketevento"))
const AsyncEventAttendancePage = lazy(() => import("../pages/eventattendancepage"))
const AsyncPoliciesAndTermsPage =  lazy(() => import("../pages/policesandtermspage"))

const routes = [
    { path: "/", component: HomePage },
//    { path: "/login", component: AsyncLoginPage },
    { path: "/helppage", component: AsyncHelpPage },
    { path: "/eventdetail/:eventid", component: AsyncEventDetail },
    { path: "/userprofile", component: AsyncUserProfilePage },
    { path: "/quickstartpage", component: AsyncStartPage },
    { path: "/youraccountpage", component: AsyncYourAccountPage },
    { path: "/usagepoliciepage", component: AsyncUsagePoliciePage },
    { path: "/aboutticketevento", component: AsyncAboutTicketEvento },
    { path: "/eventattendancepage", component: AsyncEventAttendancePage },
    { path: "/policiesandtermspage", component: AsyncPoliciesAndTermsPage},
];

export default routes;