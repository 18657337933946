import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem, Typography, Button, Select, TextField, Box } from '@mui/material';
import Swal from 'sweetalert2';
import '../../index.css';


export const PaymentMethod: React.FC<{}> = () => {

    const { id } = useParams();
    const eventId = id;
    const [ optionBank, setOptionBank ] = useState()
    const [ valuesForm, setValuesForm ] = useState({})
    //@ts-ignore
    const { email = '', nombre = '', bank = '', amount = ''} = valuesForm;
    const [ showCancelProcess, setShowCancelProcess ] = useState<boolean>(false)

    //@ts-ignore
    const handleOnChange = ({target}) => {
        const {name, value} = target;
        setValuesForm({...valuesForm, [name]: value})
    }

    const handleOnSubmit = () => {
        console.log('Pago realizado')
        Swal.fire(
            'Good job!',
            'You clicked the button!',
            'success'
          )
    }

    return (
      <>
        { (!showCancelProcess) ?
            <Grid 
                className="paymentmethod_container">
                <form onSubmit={() => handleOnSubmit()}>
                    <Grid
                        display="flex"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                        minWidth: 400,
                        height: 600,
                        background: "white",
                        border: "1px solid",
                        }}
                    >
                        <Grid display='flex' direction='row' justifyContent='center' style={{ margin: 30}}>
                            <Typography variant='h4' color={'#0D184E'}>PaymentMethod</Typography>
                        </Grid>
                            <Grid display='flex' direction='column' alignItems='center'>
                                <label htmlFor="email">Email</label>
                            </Grid>
                        <Grid container item xs={12} 
                            display='flex' direction='column' alignItems='center'>
                            <TextField type='email' name='email' value={email}
                                        onChange={ (e) => handleOnChange(e)}
                                        style={{ minWidth: 200, maxWidth: 350, margin: 10}}>
                                Email
                            </TextField>
                        </Grid>
                        <Grid display='flex' direction='column' alignItems='center'>                        
                            <label htmlFor="select">Nombre</label>
                        </Grid>
                        <Grid container item xs={12}
                              display='flex' direction='column' alignItems='center'>
                            <TextField type='text' name='nombre' value={nombre} 
                                        onChange={ (e) => handleOnChange(e)}
                                        style={{ minWidth: 200, maxWidth: 350, margin: 10}}>
                                Nombre
                            </TextField>
                        </Grid>
                        <Grid display='flex' direction='column' alignItems='center'>
                            <label htmlFor="select">Seleccione el banco</label>
                        </Grid>
                        <Grid container item xs={12} 
                              display='flex' direction='column' alignItems='center'>
                            <Select style={{ minWidth: 210, maxWidth: 350, margin: 10}}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                name='bank'
                                value={bank}
                                label="Bank"
                                onChange={(e) => handleOnChange(e)}
                                >
                                <MenuItem value='Bancolombia'>Bancolombia</MenuItem>
                                <MenuItem value='Colpatria'>Colpatria</MenuItem>
                                <MenuItem value='Banco de Bogota'>Banco de Bogota</MenuItem>
                                <MenuItem value='Banco de occidente'>Banco de occidente</MenuItem>
                                <MenuItem value='Banco Caja Social'>Banco Caja Social</MenuItem>
                            </Select>
                        </Grid>
                        <Grid display='flex' direction='column' alignItems='center'>
                            <label htmlFor="select">Ingrese el monto</label>
                        </Grid>
                        <Grid container item xs={12}
                              display='flex' direction='column' alignItems='center'>
                            <TextField type='number' name='amount' value={amount} 
                                        onChange={(e) => handleOnChange(e)}
                                        style={{ minWidth: 200, maxWidth: 350, margin: 10}}></TextField>
                        </Grid>
                        <Grid display='flex' direction='row'>
                            <Button type='submit' 
                                    style={{ width: 100,
                                            margin: 10,
                                            background: '#0D184E',
                                            color: 'white',
                                            border: '1px solid'
                                            }}
                                            onClick={() => handleOnSubmit()}>
                                Enviar
                            </Button>
                            <Button type='button'
                            style={{ margin: 10,
                                    width: 100,
                                    background: '#D71616',
                                    color: 'white'}}
                                    onClick={() => setShowCancelProcess(true)}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        : <Box style={{ width: 1030, 
            height: 600, 
            background: 'white', 
            margin: 'auto', 
            paddingTop: 160, 
            position: 'absolute'}}>
            <Grid display='flex' 
                direction='row' 
                justifyContent='center' 
                style={{ padding: 10}}>
                <Typography variant='h4'>
                    Procesando el pago...
                </Typography>
            </Grid>
            <Grid display='flex' direction='row' justifyContent='center' >
                <Grid display='flex' direction='row' justifyContent='space-around' style={{width:650}}>
                    <Button onClick={() => setShowCancelProcess(false)}>
                        Continuar el proceso de Pago
                    </Button>
                    <Button 
                            style={{ 
                                background: '#E22828',
                                color: 'white',
                                fontWeight:'bolder', 
                                padding: 10}}>
                        Cancelar el proceso de Pago
                    </Button>
                </Grid>
            </Grid>
          </Box>
        }
      </>
    );
}