import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import React from 'react'

type ThemeProp = {
    children: JSX.Element
}

export enum themePalette {
    BGD = "#12181B",
    BGW = "#fafafa",
    BLACKLIGTH_WITHE = "#212121",
    GRAYLIGTH_DARK = "#D1D4CA",
    FONT_GLOBAL = "'Roboto', sans-serif;"
}

const theme = createTheme({
    palette:{
        mode: "dark",
        background: {
            default: themePalette.BGD,
        },
        primary: {
            main: themePalette.GRAYLIGTH_DARK
        },  
    },
    typography: {
        fontFamily: themePalette.FONT_GLOBAL,
    },
    components: {
        MuiButton: {
            defaultProps:{
                style: {
                    textTransform: "none",
                    boxShadow: 'none',
                    borderRadius: '0.4em'
                }
            }
        },
        MuiCard: {
            defaultProps: {
                style: {
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px #0f0f0f",
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                style: {
                    color: 'white',
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            }
        },
    }
});

export const ThemeConfig: React.FC<ThemeProp> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

