import { instance } from "../../config/axios.config";

const getAllcategories = () => {
    return instance.get('PublicEvent/GetAllCategories', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    });
}

export { getAllcategories }