import { instance } from "../../config/axios.config";

const registerTicketBuy = (data: object) => {
    return instance.post('TicketBuy/Save', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TICKETEVENTO
        }
    })
}

export { registerTicketBuy}